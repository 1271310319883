import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getFilterParams, parseFilterParams } from '../utils/filterParams';
import config from '../config';
import { IdentifierType } from '../constants/identifiers';

const InventoryManagerComponent = require('@wework/inventory-manager-components');

const configuration = {
  appId: config.appId,
  appSecret: config.appSecret,
  baseUrl: config.apiBaseUrl,
  appVersion: config.appVersion,
  sdkVersion: config.sdkVersion,
  spacestationBaseUrl: config.ssBaseUrl,
  stargateUrl: config.stargateUrl,
  roomsBaseUrl: config.roomsBaseUrl,
  segmentsWriteKey: config.segmentsWriteKey
};

const getURIParams = (uri: string) => {
  return uri
    .slice(uri.indexOf('?') + 1)
    .split('&')
    .reduce((params, param) => {
      const [key, value] = param.split('=');
      params[key] = value;
      return params;
    }, {} as Record<string, string>);
};

function MapPage({ history }: any) {
  const userDataCtx = InventoryManagerComponent.useUserData();
  const [filters, setFilters] = useState({});

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const auth0 = useAuth0();
  const { logout, getAccessTokenSilently } = auth0;

  const [floorId, setFloorId] = useState('');
  const [buildingId, setBuildingId] = useState('');

  useEffect(() => {
    async function getAccessToken() {
      const token = await getAccessTokenSilently();
      setToken(token);
      setLoading(false);
    }
    getAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userDataCtx.userData) {
      return;
    }
    const params = new URLSearchParams(history.location.search);
    let buildingId = params.get('building');

    if (buildingId == null) {
      buildingId = userDataCtx.userData.location_uuid;
      params.set('building', buildingId);
      history.replace({
        search: params.toString()
      });
    }
    const floorId = params.get('floor');
    const filterParams = params.get('filters');
    const filters = parseFilterParams(filterParams || '');
    setFilters(filters);
    setFloorId(floorId);
    setBuildingId(buildingId);
    return () => {
      setFilters({});
      setFloorId('');
      setBuildingId('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataCtx.userData]);

  const getSearchQueryString = (
    buildingId?: string,
    floorId?: string,
    filters = {}
  ) => {
    if (buildingId) {
      const params = new URLSearchParams();
      params.set('building', buildingId);
      if (floorId) {
        params.set('floor', floorId);
      }
      const search = `${params.toString()}${getFilterParams(filters)}`;
      return search;
    }
  };

  const onFilterChange = (filters: object) => {
    const search = getSearchQueryString(buildingId, floorId, filters);
    history.replace({
      pathname: `${history.location.pathname}`,
      search
    });
    setFilters(filters);
  };

  const onBuildingChange = (building: any) => {
    const spacemanId =
      building.identifiers.find(
        (identifier: { type: string; value: string }) =>
          identifier.type === IdentifierType.Spaceman
      )?.value || building.identifiers[0].value;
    history.push({
      pathname: `${history.location.pathname}`,
      search: `?building=${spacemanId}${getFilterParams(filters)}`
    });
    setBuildingId(spacemanId);
  };

  const onChangeFloor = (newFloor: any) => {
    history.push({
      pathname: `${history.location.pathname}`,
      search: `?building=${buildingId}&floor=${newFloor.uuid}${getFilterParams(
        filters
      )}`
    });
    setFloorId(newFloor.uuid);
  };

  const didRender = (result: {
    building?: any;
    floor?: any;
    performance?: any;
  }) => {
    const { building = {}, floor = {} } = result;
    const search = getSearchQueryString(
      building.systemUUID,
      floor.uuid,
      filters
    );
    history.replace({
      pathname: `${history.location.pathname}`,
      search
    });
  };

  // eslint-disable-next-line
  const initialSelectSpace = getURIParams(location.href).space;

  if (loading) return <span>Loading</span>;
  return (
    <InventoryManagerComponent.SessionInventoryManager
      configuration={{
        ...configuration,
        spacemanToken: token,
        isStandalone: true,
        permissionEnabled: true
      }}
      buildingId={buildingId}
      floorId={floorId}
      initialSelectSpace={initialSelectSpace}
      filters={filters}
      onSignOut={logout}
      onFilterChange={onFilterChange}
      onBuildingChange={onBuildingChange}
      onChangeFloor={onChangeFloor}
      didRender={didRender}
      features={{
        ssHoldInOfficeNoteInfoBox: true
      }}
      auth0={auth0}
    />
  );
}

export default MapPage;
