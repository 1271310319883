//@ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@wework/dieter-ui/dist/index.css';

import App from './pages/App';
//@ts-ignore
import { Auth0Provider } from '@auth0/auth0-react';

ReactDOM.render(
  <React.Fragment>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_CLIENT_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
      audience="wework"
      cacheLocation="localstorage"
    >
      <App />
    </Auth0Provider>
  </React.Fragment>,
  document.getElementById('root')
);
