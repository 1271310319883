import React from 'react';
const Logo = ({ width, height, onDoubleClick }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 99 113"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="MW_Logo"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="UI-Assets" transform="translate(-723.000000, -190.000000)">
          <g
            id="icon/initial-setting4"
            transform="translate(678.000000, 152.000000)"
          >
            <g id="Group" transform="translate(40.000000, 39.951220)">
              <polygon
                id="Polygon"
                stroke="#504B59"
                strokeWidth="2"
                fill="#F8F8F8"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="55 0 102.631397 27.4664634 102.631397 82.3993902 55 109.865854 7.36860279 82.3993902 7.36860279 27.4664634"
              />
              <path
                d="M55.3846154,73.660556 L71.2070918,64.5365854 L71.2070918,46.097561 L71.0929357,46.0317333 L102.955102,27.6585366 L102.955102,82.3642664 L55.3846154,109.795606 L55.3846154,73.660556 Z"
                id="Combined-Shape"
                stroke="#504B59"
                strokeWidth="2"
                fill="#FFFFFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                onDoubleClick={onDoubleClick}
              />
              <path
                d="M71.5384615,100.262195 L71.5384615,64.918644"
                id="Polygon-Copy-8"
                stroke="#979797"
                fill="#D8D8D8"
              />
              <path
                d="M87.6923077,91.8109756 L87.6923077,36.8780488"
                id="Polygon-Copy-9"
                stroke="#979797"
                fill="#D8D8D8"
              />
              <path
                d="M102.881961,46.097561 L71.5384615,64.1716701"
                id="line-R-1-"
                stroke="#979797"
                fill="#D8D8D8"
              />
              <path
                d="M103.016013,63.7682927 L55.3846154,91.2347561"
                id="line-R-2"
                stroke="#979797"
                fill="#D8D8D8"
              />
              <path
                d="M39.2307692,46.2886441 L39.2307692,64.5365854 L54.4935636,73.3378175 L54.4935636,109.795606 L6.92307692,82.3642664 L6.92307692,27.6585366 L39.2307692,46.2886441 Z"
                id="Combined-Shape"
                stroke="#504B59"
                strokeWidth="2"
                fill="#FFFFFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                onDoubleClick={onDoubleClick}
              />
              <path
                d="M6.92307692,46.097561 L39.0872591,64.6449139"
                id="Line-L-1"
                stroke="#979797"
                fill="#D8D8D8"
              />
              <path
                d="M6.92307692,63.7682927 L54.5544741,91.2347561"
                id="Line-L-2"
                stroke="#979797"
                fill="#D8D8D8"
              />
              <path
                d="M70.679451,64.3410068 L54.7532072,73.3788564 L39.2307692,64.4279018 L55.0304552,55.3170732 L70.679451,64.3410068 Z"
                id="Combined-Shape"
                fill="#FAE7E8"
              />
              <path
                d="M39.2307692,100.262195 L39.2307692,64.8326037"
                id="Polygon-Copy-10"
                stroke="#979797"
                fill="#D8D8D8"
              />
              <path
                d="M22.3076923,91.8109756 L22.3076923,36.8780488"
                id="Polygon-Copy-11"
                stroke="#979797"
                fill="#D8D8D8"
              />
              <path
                d="M55.3846154,64.5068715 L55.3846154,45.9361917 L71.0929357,36.8780488 L71.2070918,36.9438765 L71.2070918,55.3829009 L55.3846154,64.5068715 Z"
                id="Combined-Shape"
                fill="#6772E5"
                transform="translate(63.295854, 50.692460) scale(-1, 1) translate(-63.295854, -50.692460) "
                onDoubleClick={onDoubleClick}
              />
              <path
                d="M40,36.8780488 L55.2627944,45.6792809 L55.2627944,63.9272222 L40,55.1259901 L40,36.8780488 Z"
                id="Combined-Shape"
                fill="#12E09B"
                transform="translate(47.631397, 50.402636) scale(1, -1) translate(-47.631397, -50.402636) "
                onDoubleClick={onDoubleClick}
              />
              <path
                d="M39.4192445,45.9888774 L7.69230769,27.6936604 L7.69230769,27.4664634 L55.3237049,0 L102.955102,27.4664634 L102.955102,27.6931022 L70.8679263,45.9019825 L55.2189305,36.8780488 L39.4192445,45.9888774 Z"
                id="Combined-Shape"
                stroke="#504B59"
                strokeWidth="2"
                fill="#FFFFFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                onDoubleClick={onDoubleClick}
              />
              <path
                d="M40,9.2195122 L87.6313972,36.6859756"
                id="Polygon-Copy-14"
                stroke="#979797"
                fill="#D8D8D8"
              />
              <path
                d="M23.0769231,18.4390244 L54.9873801,36.8400677"
                id="Polygon-Copy-15"
                stroke="#979797"
                fill="#D8D8D8"
              />
              <path
                d="M86.8621664,18.4390244 L55.2545398,36.6654417"
                id="Polygon-Copy-12"
                stroke="#979797"
                fill="#D8D8D8"
              />
              <path
                d="M69.9390895,9.2195122 L22.3076923,36.6859756"
                id="Polygon-Copy-13"
                stroke="#979797"
                fill="#D8D8D8"
              />
              <polyline
                id="line-R-1--Copy"
                stroke="#979797"
                points="70.5535424 64.1716701 55.1985935 54.9638852 39.2307692 64.1716701"
              />
              <path
                d="M55.2595993,37.0567029 L55.2595993,55.0007662"
                id="Path-19"
                stroke="#979797"
              />
              <polygon
                id="center-Polygon-"
                stroke="#504B59"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="55.2189305 36.8780488 71.2070918 46.097561 71.2070918 64.5365854 55.2189305 73.7560976 39.2307692 64.5365854 39.2307692 46.097561"
              />
              <path
                d="M39.4192445,45.9888774 L7.69230769,27.6936604 L7.69230769,27.4664634 L55.3237049,0 L102.955102,27.4664634 L102.955102,27.6931022 L70.8679263,45.9019825 L55.2189305,36.8780488 L39.4192445,45.9888774 Z"
                id="Combined-Shape"
                stroke="#504B59"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                onDoubleClick={onDoubleClick}
              />
              <path
                d="M39.2307692,46.2886441 L39.2307692,64.5365854 L54.4935636,73.3378175 L54.4935636,109.795606 L6.92307692,82.3642664 L6.92307692,27.6585366 L39.2307692,46.2886441 Z"
                id="Combined-Shape-Copy"
                stroke="#504B59"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M55.3846154,73.660556 L71.2070918,64.5365854 L71.2070918,46.097561 L71.0929357,46.0317333 L102.955102,27.6585366 L102.955102,82.3642664 L55.3846154,109.795606 L55.3846154,73.660556 Z"
                id="Combined-Shape-Copy-2"
                stroke="#504B59"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
