import React from 'react';
import { Route } from 'react-router-dom';
import SigninPage from '../pages/Signin';
import { useAuth0 } from '@auth0/auth0-react';

const SessionOnlyRoute = ({ component: Component, manager, ...rest }: any) => {
  const { isAuthenticated } = useAuth0();
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated) {
          return <Component {...props} manager={manager} />;
        } else {
          window.sessionStorage.setItem(
            'mw_currentlocation',
            JSON.stringify(props.location)
          );
          return <SigninPage />;
        }
      }}
    />
  );
};

export default SessionOnlyRoute;
