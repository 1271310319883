const clean = (array, deleteValue) => {
  for (var i = 0; i < array.length; i++) {
    if (array[i] === deleteValue) {
      array.splice(i, 1);
      i--;
    }
  }
  return array;
};

export const getFilterParams = filters => {
  if (!filters || Object.keys(filters).length === 0) {
    return '';
  }
  let filterQuery = `&filters=`;
  Object.keys(filters).forEach(filterKey => {
    let filter = filters[filterKey];

    if (filter) {
      if (filterKey === 'availability' && filter) {
        let availFilter = {
          ...filter,
          from: filter.from,
          to: filter.to
        };
        let availFilterVals = Object.values(availFilter).toString();
        filterQuery += `${filterKey}:${availFilterVals};`;
      } else if (filterKey === 'mimo' && filter) {
        let mimoFilter = {
          type: 'mimo',
          value: {
            ...filter
          }
        };
        filter.mi &&
          (filter.mi = {
            from: filter.mi.from,
            to: filter.mi.to
          });

        filter.mo &&
          (filter.mo = {
            from: filter.mo.from,
            to: filter.mo.to
          });

        delete mimoFilter.value.type;
        filterQuery += `${filterKey}:`;
        if (filter.mi) {
          filterQuery += `mi|${Object.values(filter.mi).toString()}`;
          if (filter.mo) {
            filterQuery += `+`;
          } else {
            filterQuery += `;`;
          }
        }

        if (filter.mo) {
          filterQuery += `mo|${Object.values(filter.mo).toString()};`;
        }
      } else if (filterKey === 'commitmentDates' && filter) {
        let commitmentDatesFilter = {
          type: 'commitmentDates',
          value: {
            ...filter
          }
        };
        filter.commitmentStart &&
          (filter.commitmentStart = {
            from: filter.commitmentStart.from,
            to: filter.commitmentStart.to
          });

        filter.commitmentEnd &&
          (filter.commitmentEnd = {
            from: filter.commitmentEnd.from,
            to: filter.commitmentEnd.to
          });

        delete commitmentDatesFilter.value.type;
        filterQuery += `${filterKey}:`;
        if (filter.commitmentStart) {
          filterQuery += `commitmentStart|${Object.values(
            filter.commitmentStart
          ).toString()}`;
          if (filter.commitmentEnd) {
            filterQuery += `+`;
          } else {
            filterQuery += `;`;
          }
        }

        if (filter.commitmentEnd) {
          filterQuery += `commitmentEnd|${Object.values(
            filter.commitmentEnd
          ).toString()};`;
        }
      } else {
        if (Array.isArray(filter)) {
          filterQuery += `${filterKey}:${filter.toString()};`;
        } else if (typeof filter === 'boolean') {
          filterQuery += `${filterKey};`;
        } else if (typeof filter === 'object') {
          filterQuery += `${filterKey}:${JSON.stringify(filter)};`;
        } else {
          filterQuery += `${filterKey}:${filter};`;
        }
      }
    }
  });

  return filterQuery;
};

export const parseFilterParams = filterParams => {
  if (!filterParams) {
    return {};
  }
  let _filters = {};
  try {
    clean(filterParams.split(';')).forEach(fltr => {
      if (fltr !== '') {
        fltr = decodeURI(fltr).replace(/\+/g, ' ');
        let fltrString = fltr.split(':');
        let fltrKey = fltrString[0];
        let fltrVals;

        if (fltrKey === 'availability') {
          let availFltrVals = fltrString[1].split(',');
          let availFrom = availFltrVals[0];
          let availTo = availFltrVals[1];
          fltrVals = { from: availFrom, to: availTo };
        } else if (fltrKey === 'mimo') {
          fltrVals = {};

          fltrString[1].split(' ').forEach(mimoItem => {
            let mimoObj = mimoItem.split('|');
            let mimoType = mimoObj[0];
            let mimoFltrVals = mimoObj[1].split(',');
            let mimoFrom = mimoFltrVals[0];
            let mimoTo = mimoFltrVals[1];
            fltrVals[mimoType] = {
              from: mimoFrom,
              to: mimoTo
            };
          });
        } else if (fltrKey === 'commitmentDates') {
          fltrVals = {};
          fltrString[1].split(' ').forEach(commitment => {
            let commitmentsObj = commitment.split('|');
            let commitmentType = commitmentsObj[0];
            let commitmentFltrVals = commitmentsObj[1].split(',');
            let commitmentFrom = commitmentFltrVals[0];
            let commitmentTo = commitmentFltrVals[1];
            fltrVals[commitmentType] = {
              from: commitmentFrom,
              to: commitmentTo
            };
          });
        } else if (fltrKey === 'spaceType' || fltrKey === 'type') {
          fltrVals =
            fltrString[1] && fltrString[1].includes(',')
              ? fltrString[1].split(',')
              : [fltrString[1]];
        } else {
          fltrVals =
            fltrString[1] && fltrString[1].includes(',')
              ? fltrString[1].split(',')
              : fltrKey === 'search'
              ? fltrString[1]
              : fltrString[1] || true;
        }

        _filters[fltrKey] = fltrVals;
      }
    });
  } catch (e) {
    return {};
  }

  return _filters;
};
