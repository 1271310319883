const overwriteSuffixKey = 'mw_api_suffix';
const getApiSuffix = () => {
  if (typeof window === 'undefined') {
    return process.env.REACT_APP_API_SUFFIX || '';
  }
  const apiSuffix = window.localStorage.getItem(overwriteSuffixKey) || '';
  return apiSuffix;
};

const config = {
  appEnv: process.env.NODE_ENV,
  appUrl: process.env.REACT_APP_CLIENT_BASE_URL,
  appId: process.env.REACT_APP_CLIENT_ID,
  appSecret: process.env.REACT_APP_CLIENT_SECRET,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  apiSuffix: getApiSuffix(),

  appTitle: 'Inventory Manager Tool',

  segmentApiKey: process.env.REACT_APP_SEGMENT_API_KEY,
  segmentPrefix:
    process.env.REACT_APP_CLASSIFICATION === 'true'
      ? '[mw-core-tools] web'
      : process.env.REACT_APP_SEGMENT_PREFIX || '[mw_omap] web',

  ssBaseUrl:
    process.env.REACT_APP_SS_BASE_URL ||
    'https://spacestation-staging.wework.com/#/',

  gitSHA: process.env.REACT_APP_GIT_SHA,

  appVersion: process.env.REACT_APP_VERSION,
  sdkVersion: process.env.REACT_APP_SDK_VERSION,

  classification:
    process.env.REACT_APP_CLASSIFICATION === 'true' ? true : false,

  stargateUrl:
    process.env.REACT_APP_STARGATE_URL || 'https://stargate.wework.com',

  splitIO: {
    token: process.env.REACT_APP_SPLIT_IO_API_KEY,
    experiments: ['lbeOccupancyMapAPIv2', 'lbeInventoryManager']
  },
  defaultLocationUUID: 'ae38915f-562a-4394-b909-d048414af41e',
  roomsBaseUrl:
    process.env.REACT_APP_ROOMS_BASE_URL || 'https://rooms-staging.wework.com',
  segmentsWriteKey:
    process.env.REACT_APP_SEGMENTS_WRITE_KEY ||
    '6JaFqtNAg7oYhAKI8UrSjoYBBeCt5vXN'
};

module.exports = config;
