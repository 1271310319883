import React, { CSSProperties } from 'react';
import SigninBox from '../../components/SigninBox';
import { Redirect } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';

import { LocationDescriptor } from 'history';

const styles: CSSProperties = {
  width: '100vw',
  height: '100vh',
  position: 'relative',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

interface Props {
  auth0: any;
}

interface State {
  loading: boolean;
  sessionId?: string | null;
  error?: Error;
}

class SigninPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const state: State = {
      loading: false
    };
    let search = window.location.search.replace(/\?/g, '');
    const searchParams = new URLSearchParams(search);
    if (searchParams.has('state') && searchParams.has('code')) {
      state.loading = true;
    }

    const sessionId = window.localStorage.getItem('mw_sid');
    if (sessionId) {
      state.sessionId = sessionId;
    }

    this.state = state;
  }

  getRedirectLocation = (): LocationDescriptor => {
    const currentLocationString = window.sessionStorage.getItem(
      'mw_currentlocation'
    );

    const lastLocation: Location =
      currentLocationString && JSON.parse(currentLocationString);

    return {
      pathname:
        lastLocation && lastLocation.pathname !== '/'
          ? lastLocation.pathname
          : `/map`,
      search: lastLocation && lastLocation.search,
      state: {
        referrer: '/'
      }
    };
  };

  render() {
    const { loginWithRedirect, isAuthenticated } = this.props.auth0;
    if (isAuthenticated) {
      const redirectTo = this.getRedirectLocation();
      return <Redirect to={redirectTo} />;
    }

    return (
      <section style={styles}>
        <div>
          <SigninBox
            onClick={() => {
              loginWithRedirect({
                appState: {
                  returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI
                }
              });
            }}
            loading={this.state.loading}
          />
        </div>
      </section>
    );
  }
}

export default withAuth0(SigninPage);
