import React from 'react';

// import CircularProgress from "@material-ui/core/CircularProgress";

import Logo from './Logo';
import config from '../config';
import { Button, Text, Card, Dimmer } from '@wework/dieter-ui';
import { Loader } from 'semantic-ui-react';

const SigninBox = ({ onClick, loading }) => {
  if (loading) {
    return (
      <Card style={{ height: 150 }}>
        <Dimmer active inverted>
          <Loader inverted>Signing in...</Loader>
        </Dimmer>
      </Card>
    );
  }
  let message = (
    <>
      Welcome To <br /> Inventory Management Tool!
    </>
  );
  if (config.classification) {
    message = (
      <>
        Welcome To <br /> Classification and UUID Mapping Tool!
      </>
    );
  }

  return (
    <Card style={{ padding: 40, display: 'flex', alignItems: 'center' }}>
      <figure>
        <Logo width="105px" height="119px" />
      </figure>
      <Text type="headline" component="h3" style={{ textAlign: 'center' }}>
        {message}
      </Text>
      <Button
        style={{ marginTop: 12 }}
        primary
        // variant="contained"
        onClick={onClick}
      >
        Sign in using Google
      </Button>
    </Card>
  );
};

export default SigninBox;
