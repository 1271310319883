import React, { Component } from 'react';
import { HashRouter as Router, Route } from 'react-router-dom';
import { UserDataProvider } from '@wework/inventory-manager-components';

// @ts-ignore
import SigninPage from '../Signin';
import SessionRoute from '../../components/SessionRoute';
import MapPage from '../MapPage';

import './App.css';

interface Props {
  session: any;
  devtoolEnabled: boolean;
  manager: any;
}

class App extends Component<Props> {
  render() {
    return (
      <Router>
        <>
          <Route exact path="/" component={SigninPage} />
          <UserDataProvider>
            <SessionRoute path="/map" component={MapPage} />
          </UserDataProvider>
        </>
      </Router>
    );
  }
}

export default App;
